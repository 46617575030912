import React, { Component } from 'react';
import Inicio from './inicio';
import privacyPolicy from './Page/protocoloDePrivacidad';
import serverPolicy from './Page/terminosDeUso';
import cancelDatos from './Page/cancelDatos';
import ErrorInfo from './Page/ErrorInfo';
import { Router, Route } from "react-router-dom";
import {createBrowserHistory} from 'history'
const browserHistory = createBrowserHistory()
class App extends Component {
  render() {
    console.log('----App----')
    return (
      <Router history={browserHistory}>
        <Route exact path="/" component={Inicio} />
        <Route exact path="/protocoloDePrivacidad" component={privacyPolicy} />
        <Route exact path="/terminosDeUso" component={serverPolicy} />
        <Route exact path="/errorInfo" component={ErrorInfo} />
        <Route exact path="/cancelDatos" component={cancelDatos} />
      </Router>
    );
  }
}

export default App;
