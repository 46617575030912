import React from "react";
import { enquireScreen } from "enquire-js";
import DocumentTitle from "react-document-title";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyPolicy extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Protocolo de Privacidad" />
        <h2>「 Política de Privacidad de Préstamos en Perú」</h2>
        <p>
          Este documento corresponde a la Política de Privacidad de FlexSol (en
          adelante, "FlexSol"), la cual se ajusta a las regulaciones vigentes
          sobre Protección de Datos Personales. En este documento se detallan
          los propósitos de la información proporcionada por usted, quiénes son
          responsables de su tratamiento, las medidas de protección y los medios
          para ejercer sus derechos como titular de los Datos Personales, entre
          otros aspectos. Lea detenidamente este documento para comprender qué
          información recopilamos sobre usted, cómo la utilizamos y las
          circunstancias en las que la compartiremos con terceros.
        </p>
        <p></p>
        <p>
          El objetivo de esta política es informar a los usuarios sobre nuestro
          compromiso de proteger sus datos personales y las pautas que seguimos
          para procesar estos datos en el marco de nuestro negocio.
        </p>
        <p></p>
        <p>
          Al utilizar esta aplicación o los sitios web relacionados, usted
          acepta esta política de privacidad y otorga su consentimiento libre,
          claro, inequívoco e informado para la recopilación y el procesamiento
          de la información del usuario por parte de esta aplicación. Asimismo,
          el usuario declara su consentimiento para todos los aspectos revelados
          en este documento y nos autoriza a procesar sus datos personales para
          los fines informados.
        </p>
        <p></p>
        <p>Consentimiento</p>
        <p></p>
        <p>
          Al utilizar esta aplicación, usted acepta esta Política de Privacidad
          y otorga su consentimiento libre, expreso, inequívoco e informado para
          que esta aplicación recopile y procese información del usuario.
          Asimismo, el usuario declara estar de acuerdo con todos los aspectos
          expuestos en este documento y nos autoriza a procesar sus datos
          personales para los fines mencionados.
        </p>
        <p></p>
        <p>
          Es importante destacar que, aunque la interacción con el usuario está
          prevista para realizarse a través de la aplicación, FlexSol podría
          habilitar un sitio web para interactuar con los usuarios, y el solo
          uso de este implicaría la aceptación de esta Política de Privacidad en
          los términos indicados anteriormente.
        </p>
        <p></p>
        <p>
          Finalmente, FlexSol no necesita consentimiento para procesar los datos
          personales obtenidos de fuentes accesibles al público; además, podrá
          procesar los datos personales de fuentes no públicas siempre que
          dichas fuentes cuenten con su consentimiento para tratar y transferir
          dichos datos personales.
        </p>
        <p></p>
        <p>Permisos solicitados y datos recopilados</p>
        <p></p>
        <p>
          Cuando utiliza la aplicación FlexSol o nuestros servicios, recopilamos
          la siguiente información personal de usted con su permiso.
        </p>
        <p></p>
        <p>
          Podemos combinar la información proporcionada por usted con
          información recopilada de otras fuentes con el fin de actualizar datos
          incompletos o sustituirlos, basándonos en consultas de fuentes de
          acceso público.
        </p>
        <p></p>
        <p>
          Esta información, así como la información combinada, puede usarse para
          cualquiera de los fines mencionados en este documento.
        </p>
        <p></p>
        <p>
          La siguiente información se recopila de manera segura, y mantenemos
          sus datos seguros.
        </p>
        <p></p>
        <p>
          Información recopilada al utilizar la aplicación y/o el sitio web.
        </p>
        <p></p>
        <p>SMS(registro de SMS)</p>
        <p></p>
        <p>
          Recopilamos todos sus datos SMS, pero solo monitoreamos los mensajes
          relacionados con transacciones financieras. Específicamente,
          monitoreamos el nombre del remitente, la descripción y el monto de la
          transacción para evaluar el riesgo crediticio. Esto nos permite
          identificarlo adecuadamente y verificar si califica para ser
          financiado por nosotros. Los datos SMS se cargan al servidor de
          FlexSol (https://fin.flexsol.tech/fint) a través de una conexión
          segura.
        </p>
        <p></p>
        <p>Imagen:</p>
        <p></p>
        <p>
          Necesitamos su permiso para acceder a la información de
          almacenamiento, lo cual permitirá que la aplicación le solicite cargar
          fotos y/o documentos durante el proceso de solicitud de préstamo para
          completar la solicitud. FlexSol cargará y transmitirá los datos de
          almacenamiento desde su teléfono a https://fin.flexsol.tech/fint
        </p>
        <p></p>
        <p>Ubicaciones</p>
        <p></p>
        <p>
          Recopilamos y monitoreamos información sobre las ubicaciones de sus
          dispositivos para evaluar el perfil del cliente y prevenir fraudes.
          Los datos de ubicación se cargan al servidor de FlexSol
          (https://fin.flexsol.tech/fint).
        </p>
        <p></p>
        <p>Celular:</p>
        <p></p>
        <p>
          Recopilamos y monitoreamos información sobre su dispositivo,
          incluyendo nombre, modelo, región, idioma, identificación única,
          información de hardware y software, estado, hábitos de uso,
          identificadores únicos como IMEI y números de serie. Esta información
          nos ayuda a identificar el dispositivo desde el cual se realiza la
          solicitud de préstamo y otras evaluaciones adicionales para determinar
          su perfil y prevenir fraudes.
        </p>
        <p></p>
        <p>
          Además, recopilamos su lista detallada de aplicaciones instaladas para
          evaluar su capacidad para solicitar un préstamo y su situación de
          deuda. Los datos del dispositivo móvil se cargan al servidor de
          FlexSol (https://fin.flexsol.tech/fint).
        </p>
        <p></p>
        <p>Cámara:</p>
        <p></p>
        <p>
          Solicitamos acceso a la cámara de su dispositivo móvil para que pueda
          identificarse mediante un "selfie" y proporcionarnos los documentos
          necesarios para el proceso de solicitud y evaluación de su perfil de
          cliente. Los datos de la cámara se cargan al servidor de FlexSol
          (https://fin.flexsol.tech/fint).
        </p>
        <p></p>
        <p>Calendario:</p>
        <p></p>
        <p>
          Solicitamos acceso a su calendario para poder programar la fecha de
          pago correspondiente y los recordatorios necesarios. De esta manera,
          gestionamos nuestro riesgo crediticio y evitamos pagos vencidos. Los
          datos del calendario se cargan al servidor de FlexSol
          (https://fin.flexsol.tech/fint).
        </p>
        <p></p>
        <p>Datos de Contactos：</p>
        <p></p>
        <p>
          Necesitamos que seleccione manualmente el contactos de emergencia
          desde su lista de contactos incluido nombre y número de teléfono, esta
          función sirve para cuando no podemos poner en contacto contigo.
          Solamente los contactos seleccionados manualmente por los usuarios se
          transmitirán a nuestra aplicación de manera encriptada
          (https://fin.flexsol.tech/fint) , y se guardarán por 1 año a partir de
          la fecha de carga. Nunca recopilaremos su información sin
          consentimiento. Si desea eliminar sus datos privados, puede enviar un
          correo electrónico a servicio@flexsol.tech. Envíe una solicitud al
          servicio de atención al cliente y podemos eliminarla manualmente
          dentro de (3-5 días hábiles).
        </p>
        <p></p>
        <p>Actividad en apps:</p>
        <p></p>
        <p>
          Necesitamos obtener la actividad en apps, incluido nombre del paquete,
          nombre de la aplicación, la hora de la primera instalación y la hora
          de actualización de la aplicación, para evaluar si el cliente es
          elegible para solicitar un préstamo. Cargaremos esta información a
          nuestro servidor (https://fin.flexsol.tech/fint) encriptado, y se usa
          para identificar y analizar comportamiento y riesgo en múltiples
          préstamos para evaluar si un préstamo puede procesarse, lo que ayuda a
          prevenir el fraude.
        </p>
        <p></p>
        <p>Datos de las aplicaciones instaladas</p>
        <p></p>
        <p>
          FlexSol recopila, almacena y monitorea e sube a
          https://fin.flexsol.tech/fint la información de los metadatos de cada
          aplicación instalada, incluyendo el nombre de la aplicación y del
          paquete, la hora de instalación y actualización, el nombre de la
          versión y el código de la versión de todas las aplicaciones instaladas
          en su dispositivo. Utilizamos esta información para evaluar su
          solvencia y evitar el fraude en los préstamos. FlexSol nunca
          compartirá los datos con terceros sin su permiso. Sólo se recopilarán
          bajo su consentimiento. Los datos serán transferidos y cargados
          mediante una conexión segura (https).
        </p>
        <p></p>
        <p>Información personal del usuario</p>
        <p></p>
        <p>
          Para realizar control de riesgo y evaluar la elegibilidad del usuario,
          necesitamos usar las informaciones personales incluido nombre
          completo, estado civil, género, fecha de nacimiento, edad, país,
          identificación oficial vigente, documento nacional de identidad (DNI),
          correo electrónico.
        </p>
        <p></p>
        <p>
          Se va realizar el depósito bancario directamente al número de cuenta
          proporcionados por los usuarios.
        </p>
        <p></p>
        <p>El cooperador</p>
        <p></p>
        <p>
          El SDK que utilizamos para conectar con el servicio de proveedor de
          Deeplink se entrega a través de múltiples canales (por ejemplo,
          Google, Facebook) con el fin de distinguir la fuente de atribución del
          canal del usuario y transmitir la identidad de cada canal, pero
          nuestro socio Deeplink no accede ni utiliza ninguna información de la
          cuenta social del usuario, tampoco nuestra propia app accede ni
          utiliza ninguna información de la cuenta social del usuario.
        </p>
        <p></p>
        <p>
          En resumen, las finalidades para las que utilizaremos tus datos serán
          las siguientes:
        </p>
        <p></p>
        <p>
          Nosotros podríamos combinar la información brindada por usted con
          información recolectada de otras fuentes con la finalidad de
          actualizar datos que se encuentren incompletos o sustituirlos, en base
          a consultar de fuentes de acceso público.
        </p>
        <p></p>
        <p>
          Esta información, y la información combinada, podría ser usada para
          cualquiera de los usos y fines señalados en el presente documento.
          Para brindarte nuestros servicios y mejorar tu experiencia en nuestra
          página web y aplicación.
        </p>
        <p></p>
        <p>
          Para fines de funcionalidad y operatividad de la web y la aplicación.
        </p>
        <p></p>
        <p>
          Para poder brindarte soporte, resolver cualquier duda o consulta y
          resolver tus reclamos.
        </p>
        <p></p>
        <p>Para proteger tu seguridad y la de nuestra web y aplicación.</p>
        <p></p>
        <p>
          Para satisfacer un interés legítimo (que no anula nuestro deber de
          proteger tus datos personales) como la investigación y el desarrollo
          de nuestros servicios, promover el sitio web y proteger nuestros
          derechos e intereses legales.
        </p>
        <p></p>
        <p>
          Para procesar tus datos con el objeto de cumplir con una obligación
          legal.
        </p>
        <p></p>
        <p>
          Para procesar tus datos con el objeto de cumplir con una obligación
          contractual.
        </p>
        <p></p>
        <p>Información proporcionada por usted</p>
        <p></p>
        <p>
          FlexSol recabará de usted (en adelante el “Titular”) los siguientes
          datos personales (en lo sucesivo los “datos” o los “datos personales”)
          en tu calidad de uno de nuestros clientes:
        </p>
        <p></p>
        <p>
          datos de contacto (correo electrónico e número de teléfono:solo para
          verificación de inicio de sesión);datos de identificación (nombre,
          fecha de nacimiento, captura de imagen, identificación oficial, género
          asignado);
        </p>
        <p></p>
        <p>datos biométricos;</p>
        <p></p>
        <p>datos de localización y geolocalización (ubicación aproximada);</p>
        <p></p>
        <p>datos laborales y educativos;A otras sociedades controladoras</p>
        <p></p>
        <p>
          datos de comunicación con FlexSol (solicitudes de atención al cliente,
          respuestas a aclaraciones u otras comunicaciones a través de mensajes
          SMS, correo electrónico o grabación de llamadas);
        </p>
        <p></p>
        <p>
          datos de tu teléfono celular o dispositivo móvil (información del
          hardware, IMEI, dirección IP, dirección MAC, GPS ADID o identificación
          de otro dispositivo, operador móvil);
        </p>
        <p></p>
        <p>
          datos patrimoniales y financieros (ingresos o datos o documentos de tu
          empleo, grado de escolaridad, propósito o uso que se le dará al
          préstamo);
        </p>
        <p></p>
        <p>datos que proporciones en tu solicitud de préstamo;</p>
        <p></p>
        <p>
          datos transaccionales de las operaciones que realizas con nosotros o a
          través de nosotros, así como de las operaciones que realizas con
          transmisores de dinero, instituciones de fondos de pago electrónico,
          instituciones de financiamiento colectivo, instituciones de crédito y
          otras instituciones financieras o comercios electrónicos;
        </p>
        <p></p>
        <p>
          datos de uso de la aplicación de FlexSol y de otras aplicaciones
          (detalles de instalación, tráfico, navegación).
        </p>
        <p></p>
        <p>Necesidad de la recolección y procesamiento de tus datos</p>
        <p></p>
        <p>
          FlexSol te informa que los datos personales y personales sensibles
          (recolectados incidentalmente) que te solicita, de conformidad con el
          presente Aviso de Privacidad, son necesarios y suficientes para que
          FlexSol realice un proceso de evaluación de riesgo de crédito y
          probabilidad de pago (scoring crediticio) en base a tu perfil y así
          poder determinar tu capacidad crediticia, lo cual es la base
          fundamental de la determinación de FlexSol para aprobar o rechazar el
          otorgamiento de tu solicitud de crédito y dar inicio a la relación
          jurídica contigo, así como para las demás finalidades primarias y
          secundarias que se señalan en el presente Aviso de Privacidad. Cabe
          resaltar que, la decisión final al respecto no es tomada de forma
          manual por un oficial o empleado de FlexSol, sino que, dicha decisión
          es tomada por los sistemas automatizados de FlexSol de conformidad con
          lo señalado en la sección “Decisiones Automatizadas” del presente
          Aviso de Privacidad.
        </p>
        <p></p>
        <p>¿CÓMO OBTENEMOS Y ACTUALIZAMOS TUS DATOS?</p>
        <p></p>
        <p>Los datos personales serán obtenidos y actualizados cuando:</p>
        <p></p>
        <p>Bajas la aplicación de FlexSol y/o nos solicitas un préstamo;</p>
        <p></p>
        <p>
          Interactúas con nosotros, incluyendo tus datos personales que
          voluntariamente compartes con nuestro departamento de atención al
          cliente o con nuestros empleados y agentes;
        </p>
        <p></p>
        <p>
          Desde tú teléfono celular o dispositivo móvil otorgas acceso y
          permisos que se describe abajo;
        </p>
        <p></p>
        <p>
          Se hace consultas a través de los buros de crédito o agencias para
          prevenir el fraude;
        </p>
        <p></p>
        <p>
          Se consulta Cookies, web beacons y otros medios remotos de recolección
          automática de datos;
        </p>
        <p></p>
        <p>
          Se consulta a los Transmisores de dinero instituciones de fondos de
          pago electrónico, instituciones de financiamiento colectivo,
          instituciones de crédito y otras instituciones financieras y comercios
          electrónicos, con tu consentimiento, cuando sea necesario; y/o
        </p>
        <p></p>
        <p>
          Se consulta a terceros y otras fuentes públicas, con tu
          consentimiento, siempre que sea necesario.
        </p>
        <p></p>
        <p>Objetivo de la recogida de datos</p>
        <p></p>
        <p>
          Las finalidades primarias (en lo sucesivo, las “Finalidades
          Primarias”) que dan origen y son necesarias para el mantenimiento y
          cumplimiento de la relación jurídica entre FlexSol y tú son las
          siguientes:
        </p>
        <p></p>
        <p>
          Verificar tu identidad, prevenir fraudes y cumplir con nuestras
          obligaciones de “conoce a tu cliente” bajo las leyes aplicables de
          prevención de lavado de dinero;
        </p>
        <p></p>
        <p>
          Crear tu perfil crediticio y asignarte una calificación crediticia
          para determinar si eres elegible para usar los productos y servicios
          de FlexSol;
        </p>
        <p></p>
        <p>
          Llevar a cabo la identificación y el conocimiento mediante recolección
          de datos personales, así como la integración de tu expediente que se
          mantendrá en la infraestructura tecnológica y sistemas de información
          de FlexSol y en otros archivos;
        </p>
        <p></p>
        <p>
          Incorporar los datos personales en instrumentos jurídicos, dar
          cumplimiento a los contratos que celebres con FlexSol, incluyendo,
          pero no limitado a ciertos contratos de usuario de nuestra aplicación
          y plataformas y los contratos de préstamo o crédito entre el Titular y
          FlexSol y administrar y manejar dichos contratos;
        </p>
        <p></p>
        <p>
          Originar, gestionar, dar servicio, mantener y exigir las deudas del
          Titular;
        </p>
        <p></p>
        <p>
          Brindarte servicios y gestionar tu cuenta con FlexSol y darte soporte
          como cliente;
        </p>
        <p></p>
        <p>
          Enviarte información de transacciones o notificaciones de
          transacciones, recordatorios de pago y anuncios a través de servicios
          de telefonía automáticos o mensajes pre-grabados, mensajes de texto o
          email;
        </p>
        <p></p>
        <p>
          Ceder o transmitir a un tercero, mediante cualquier medio, los
          derechos y/u obligaciones derivadas de los contratos antes señalados;
        </p>
        <p></p>
        <p>
          Utilizar los datos personales, en cualquier tipo de acto o diligencia
          de cobranza judicial o extrajudicial;
        </p>
        <p></p>
        <p>
          Cumplir con todas las leyes, reglamentos y disposiciones de carácter
          general aplicables;
        </p>
        <p></p>
        <p>Investigar cambios en tu perfil transaccional;</p>
        <p></p>
        <p>Prevención de fraudes;</p>
        <p></p>
        <p>
          Obtener datos personales para mejorar los modelos crediticios de
          FlexSol, sus modelos de datos y otros servicios; y
        </p>
        <p></p>
        <p>
          Analizar el comportamiento del cliente y mantener la calidad de
          nuestros servicios.
        </p>
        <p></p>
        <p>
          Las finalidades secundarias (en lo sucesivo, las “Finalidades
          Secundarias”) que no son necesarias para el mantenimiento y
          cumplimiento de la relación jurídica entre FlexSol y tú, son las
          siguientes:
        </p>
        <p></p>
        <p>
          Envío de noticias, invitaciones a eventos de FlexSol y/o sus empresas
          subsidiarias y afiliadas;
        </p>
        <p></p>
        <p>Realización de encuestas para mejorar nuestro servicio;</p>
        <p></p>
        <p>
          Uso de imágenes y testimonios que nos proporciones voluntariamente
          para fines publicitarios; y
        </p>
        <p></p>
        <p>
          La mercadotecnia, publicidad y/o promoción de los productos y/o
          servicios que sean ofrecidos por FlexSol al Titular, por cualquier
          medio material y/o electrónico. Con tu consentimiento al presente
          Aviso de Privacidad otorgas tu consentimiento expreso para que podamos
          contactarte con la finalidad de ofrecerte servicios financieros.
        </p>
        <p></p>
        <p>
          Tu consentimiento es necesario para las finalidades secundarias. Si no
          estás de acuerdo con el uso de tus datos de contacto para dichas
          finalidades secundarias, envíanos un correo a servicio@flexsol.tech.
          Esperaremos cinco días hábiles antes de usar tus datos personales para
          finalidades secundarias.
        </p>
        <p></p>
        <p>¿Con quién compartimos sus datos?</p>
        <p></p>
        <p>
          Los datos personales se transferirán a nivel nacional e internacional,
          a las personas o entidades ubicadas en los siguientes tipos,
          categorías o sectores, con las siguientes finalidades:
        </p>
        <p></p>
        <p>
          A. A otras sociedades controladoras, subsidiarias o afiliadas, o a una
          sociedad matriz o a cualquier sociedad del mismo grupo de FlexSol, que
          operen bajo los mismos procesos y políticas internas, con la finalidad
          de hacer uso de servicios compartidos dentro del grupo de FlexSol y
        </p>
        <p></p>
        <p>además para:</p>
        <p></p>
        <p>
          Llevar a cabo la identificación y el conocimiento mediante recolección
          de datos personales del Titular, así como la integración de los datos
          personales en la infraestructura tecnológica de FlexSol, así como en
          sus sistemas de información y otros archivos;
        </p>
        <p></p>
        <p>
          Incorporar los datos personales del Titular en instrumentos jurídicos,
          dar cumplimiento a las obligaciones del Titular bajo los contratos
          celebrados entre el Titular y FlexSol, incluyendo de manera
          enunciativa, más no limitativa, ciertos contratos de usuario de
          nuestra aplicación y plataformas y los contratos de préstamo o crédito
          entre el Titular y FlexSol y administrar y manejar dichos contratos;
        </p>
        <p></p>
        <p>
          Ceder o transmitir a un tercero, mediante cualquier medio, los
          derechos y/u obligaciones derivadas de los contratos antes señalados;
        </p>
        <p></p>
        <p>
          Utilizar los datos personales, en cualquier tipo de acto o diligencia
          de cobranza judicial o extrajudicial;
        </p>
        <p></p>
        <p>
          Cumplir con todas las leyes, reglamentos y disposiciones de carácter
          general aplicables;
        </p>
        <p></p>
        <p>
          Prospectar y evaluar los datos personales para crear y asignar al
          titular una calificación crediticia o grado de calificación similar.
        </p>
        <p></p>
        <p>
          Obtener información para mejorar nuestro modelo crediticio, de datos y
          de servicios;
        </p>
        <p></p>
        <p>Investigar cambios en tu perfil transaccional;</p>
        <p></p>
        <p>Prevención de fraudes;</p>
        <p></p>
        <p>
          Originar, gestionar, dar servicio, mantener y exigir la deuda del
          Titular.
        </p>
        <p></p>
        <p>
          B. A autoridades nacionales y extranjeras competentes, con la
          finalidad de dar cumplimiento a alguna ley, reglamento o disposición
          legal aplicable, así como con la finalidad de prevenir un daño.
        </p>
        <p></p>
        <p>
          C. A terceros que adquieran la cartera crediticia de FlexSol o que
          otorguen financiamiento a FlexSol con la finalidad de que FlexSol
          pueda obtener financiamiento o recursos.
        </p>
        <p></p>
        <p>
          D. A terceros interesados en otorgar financiamientos o adquirir las
          acciones o activos de FlexSol o de sus accionistas o filiales en Perú
          o alrededor del mundo o en fusionarse con FlexSol.
        </p>
        <p></p>
        <p>
          E. Con nuestros socios comerciales, para fines de prospección
          comercial y servicios relacionados ofrecidos por FlexSol y para darte
          acceso a los servicios de terceros dentro de la aplicación de FlexSol.
        </p>
        <p></p>
        <p>
          Asimismo, y de acuerdo con las leyes vigentes, FlexSol deberá, con el
          objeto de cumplir con las Finalidades Primarias descritas en el
          presente, transferir de tiempo en tiempo, información de crédito del
          Titular a las Sociedades de Información Crediticia o Burós de Crédito,
          y en este sentido, al aceptar el presente Aviso de Privacidad (en lo
          sucesivo, este “Aviso”) y al consentir a la celebración del Contrato
          de Usuario, usted confirma que podemos transmitir su información a
          dichas Sociedades de Información Crediticia o Burós de Crédito,
          incluyendo información sobre el incumplimiento a los contratos
          celebrados entre el Titular y FlexSol, entre otros el Contrato del
          Usuario.
        </p>
        <p></p>
        <p>
          También compartimos tus datos personales con terceros prestadores de
          servicios relacionados con los desembolsos, la transferencia de
          fondos, servicios de pagos, proveedores de seguridad de información,
          aseguradoras, despachos de cobranza, asesores contables y legales,
          proveedores de software para análisis de datos, transferencias y
          servicios de almacenamiento y otros prestadores de servicios que
          procesan tus datos personales por cuenta de FlexSol.
        </p>
        <p></p>
        <p>Puedes negarte o revocar el consentimiento para tratar tus datos</p>
        <p></p>
        <p>
          En caso de que desees revocar tu consentimiento o negarte al
          tratamiento de tus datos personales para las Finalidades Secundarias,
          con posterioridad a la firma y aceptación del presente Aviso, deberás
          enviar un correo electrónico a la dirección indicada en la sección
          anterior al Departamento de Protección de Datos Personales/Equipo de
          Atención al Cliente, servicio@flexsol.tech, siguiendo el procedimiento
          para la Negativa al Tratamiento y Revocación del Consentimiento que se
          describe en este Aviso.
        </p>
        <p></p>
        <p>
          De acuerdo con lo anterior, el Departamento de Protección de Datos
          Personales/Equipo de Atención al Cliente es el encargado de atender
          todas las solicitudes de ejercicio de los derechos ARCO (acceso,
          rectificación, cancelación u oposición) mismo que puedes contactar en
          caso de cualquier duda o solicitud con respecto a tus datos personales
          a través del correo electrónico y teléfono antes indicados.
        </p>
        <p></p>
        <p>
          ¿QUÉ MEDIOS TIENES PARA LIMITAR EL USO Y DIVULGACIÓN DE DATOS
          PERSONALES?
        </p>
        <p></p>
        <p>
          FlexSol ha creado un listado de exclusión para personas que no deseen
          recibir publicidad de FlexSol. Si recibes publicidad de FlexSol y no
          deseas seguir recibiéndola, podrás enviar un correo electrónico con tu
          nombre completo a servicio@flexsol.tech, solicitando tu eliminación de
          la lista de distribución con base en la cual se lleva a cabo el envío
          de la información o publicidad. En el mismo correo electrónico podrás
          solicitar más datos acerca de este listado.
        </p>
        <p></p>
        <p>Tus derechos ARCO</p>
        <p></p>
        <p>
          Sobre los datos que gestionamos y almacenamos en ficheros. Tiene los
          siguientes derechos:
        </p>
        <p></p>
        <p>El derecho a solicitar el acceso a sus datos personales</p>
        <p></p>
        <p>El derecho a solicitar la corrección de sus datos personales</p>
        <p></p>
        <p>
          El derecho a que se eliminen sus datos personales (derecho al olvido).
        </p>
        <p>
          - Eliminación de cuentas y datos:Le brindamos el derecho de cancelar
          su cuenta después de liquidar las órdenes de préstamo. Si desea dejar
          de usar nuestro servicio y eliminar su cuenta, ingrese a
          https://www.flexsol.tech/cancelDatos para enviar una solicitud de
          eliminación, le pediremos que proporcionar información de su cuenta y
          pruebas relacionadas para verificar y validar su identidad; Después de
          la verificación, eliminaremos su cuenta y los datos relacionados.
        </p>
        <p></p>
        <p>
          El derecho a oponerse al procesamiento de sus datos personales y, en
          ciertas circunstancias, a restringir el procesamiento de sus datos
          personales.
        </p>
        <p></p>
        <p>
          El derecho incondicional a oponerse al procesamiento de sus datos
          personales con fines de marketing directo.
        </p>
        <p></p>
        <p>
          Si el procesamiento de su información personal se basa en su
          consentimiento, tiene derecho a retirar su consentimiento en cualquier
          momento. Su retirada no afectará a la licitud del tratamiento
          realizado antes de su retirada del consentimiento. Puede retirar su
          consentimiento en su perfil, en nuestro sitio web o contactándonos por
          correo electrónico a servicio@flexsol.tech.
        </p>
        <p></p>
        <p>Modificaciones al política de privacidad</p>
        <p></p>
        <p>
          FlexSol se reserva el derecho de efectuar en cualquier momento cambios
          o modificaciones al presente Política de Privacidad, ante la necesidad
          de proporcionarle la protección continua y adecuada a sus datos
          personales, así como para la atención de novedades legislativas o
          jurisprudenciales, políticas internas, y prácticas del mercado. Los
          cambios y modificaciones estarán disponibles a través de los
          siguientes medios: anuncios en nuestras oficinas y en el portal web de
          FlexSol, a través de nuestro personal que recabe sus Datos Personales.
          Es aconsejable revisar el Política de Privacidad tras la enlace
          https://www.flexsol.tech/protocoloDePrivacidad para ser enterado de
          cada modificación que habrá en el futuro. Si tienen cualquier duda
          sobre el Política de Privacidad, por favor no duden en ponerse en
          contacto con atención al cliente correo electrónico
          (servicio@flexsol.tech).
        </p>
        <p></p>
        <p>Cesión de posición contractual y cesión de derechos</p>
        <p></p>
        <p>
          El usuario autoriza expresamente la cesión de derechos y/o la cesión
          de posición contractual de esta política de privacidad y de los datos
          personales en favor de cualquier persona que (i) quede obligada por la
          presente política y/o (ii) que sea el nuevo responsable de los bancos
          de datos que contengan los datos personales.
        </p>
        <p></p>
        <p>
          Luego de producida la cesión, FlexSol no tendrá ninguna
          responsabilidad con respecto de cualquier hecho que ocurra a partir de
          la fecha de la cesión.
        </p>
        <p></p>
        <p>
          El nuevo responsable del banco de datos asumirá todas y cada una de
          las obligaciones de FlexSol establecidas en la presente política
          respecto al tratamiento, resguardo y conservación de los datos
          personales.
        </p>
        <p></p>
        <p>
          Sobre el carácter obligatorio de los datos personales, y las
          consecuencias de no proporcionar la información personal requerida
        </p>
        <p></p>
        <p>
          La recopilación de los datos personales es necesaria y obligatoria
          para poder cumplir con las finalidades descritas en la presente
          política.
        </p>
        <p></p>
        <p>
          En caso no nos permitas acceder a la información personal requerida,
          te comunicamos que, no podremos brindarte los servicios que prestamos
          a través de nuestra página web y aplicación móvil, y en consecuencia,
          no podrás realizar las operaciones financieras que en ellas se
          ofrecen.
        </p>
        <p></p>
        <p>Seguridad de los datos personales</p>
        <p></p>
        <p>
          En cumplimiento de la normativa vigente, hemos adoptado las medidas
          técnicas y organizativas de seguridad necesarias para garantizar la
          protección de los datos personales conservados, transmitidos y
          procesados de nuestros usuarios con el fin de evitar su alteración,
          pérdida o el tratamiento o acceso no autorizados que puedan afectar a
          la integridad, confidencialidad y disponibilidad de la información,
          teniendo en cuenta el estado de la tecnología, la naturaleza de los
          datos almacenados y los riesgos a los que están expuestos.
        </p>
        <p></p>
        <p>
          Asimismo, los datos personales son recolectados a través del canal
          cifrado y como parte del proceso de registro. De igual forma, la
          contraseña utilizada en el momento de registro se encuentra cifrada.
        </p>
        <p></p>
        <p>
          Por su parte, la información entre el navegador y la página web -o
          aplicación móvil-, se transfiere de forma encriptada a través de
          Secure Socket Layer (SSL). Sobre este último punto, al transmitir
          información.
        </p>
        <p></p>
        <p>
          No obstante, a pesar de que en FlexSol realizamos nuestros mejores
          esfuerzos para proteger tus datos personales mediante el empleo de
          diversas técnicas de seguridad, la transmisión de información mediante
          las redes de comunicación y de Internet no es totalmente segura debido
          a actos ilícitos como ciberataques o acciones imputables al propio
          usuario al no tener precaución con sus propios datos.
        </p>
        <p></p>
        <p>
          En tal sentido, toda la información que nos proporciones se enviará
          por tu cuenta y riesgo. FlexSol no se hace responsable por
          interceptaciones ilegales o violación de sus sistemas o bases de datos
          por parte de personas no autorizadas, así como la indebida utilización
          de la información obtenida por esos medios, o de cualquier intromisión
          ilegítima que escape al control de ésta y que no le sea imputable.
        </p>
        <p></p>
        <p>
          A pesar de lo anterior, en caso de que FlexSol detecte una violación a
          la seguridad de los datos personales proporcionados, y ello signifique
          un alto riesgo para tus derechos y libertades, te comunicaremos
          inmediatamente tal situación en un lenguaje claro y sencillo, de modo
          que puedas comprender fácilmente la naturaleza de la infracción
          cometida contra tus datos personales.
        </p>
        <p></p>
        <p>
          Dicha comunicación no será necesaria cuando FlexSol haya adoptado
          medidas de protección técnicas y organizativas apropiadas aplicadas a
          los datos personales afectados, de modo que éstos resulten
          indescifrable para quien intentó acceder a ellos (ej. el cifrado).
          Tampoco será necesario cuando suponga un esfuerzo desproporcionado
          para FlexSol, ya que tratándose de varios usuarios afectados resulta
          más fácil para nosotros optar por una comunicación pública u otras
          opciones.
        </p>
        <p></p>
        <p>Ley y jurisdicción</p>
        <p></p>
        <p>
          Esta política de privacidad y cualquier disputa o reclamo que surjan
          como consecuencia de la misma se encuentra normada en la ley peruana.
        </p>
        <p></p>
        <p>Resolución de conflictos</p>
        <p></p>
        <p>
          Las partes se someten a la competencia de los jueces y tribunales de
          Lima, Cercado para resolver cualquier duda, diferencia o controversia
          que pudiera surgir entre las partes respecto a los alcances de las
          políticas de privacidad de FlexSol, renunciando al fuero que pudiera
          corresponderles en razón a su domicilio.
        </p>
        <p></p>
        <p>Dudas y requerimientos</p>
        <p></p>
        <p>
          Preguntas, solicitudes y comentarios asociados a esta política de
          privacidad son bienvenidos y deberán ser dirigidos a
          servicio@flexsol.tech
        </p>
        <p></p>
        <p>Contacto</p>
        <p></p>
        <p>
          Si tiene alguna pregunta o sugerencia sobre esta política de
          privacidad, no dude en ponerse en contacto con nosotros.
        </p>
        <p></p>
        <p>Correo electrónico: servicio@flexsol.tech</p>
        <p></p>
        <p>Dirección: Lima, Perú</p>
        <p></p>
        <p>Horario de trabajo: LUN-VIER 9:00 A 18:00, SAB 9:00 A 14:40</p>
      </div>
    );
  }
}
export default PrivacyPolicy;
