import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
// import downLoad from '../assets/imgs/descargar.png';
import tbg from '../assets/imgs/ant@2x.png';
import tbgMobile from '../assets/imgs/mobile/ant@2x.png';
// import title from '../assets/imgs/title.png';
class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    fetch( window.location.origin + '/getapp',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  
  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl, andro } = this.state;
    const title = 'Préstamo Simple Y Rápido, Le Ayudará A Resolver El Problema'
    const subtitle = 'En sólo tres pasos, ¡puede obtener un préstamo en tan sólo 2-24 horas!'
    const desc1 = 'Registro'
    const desc2 = 'Evaluación'
    const desc3 = 'Desembolso'
    return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': '')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
          <div className={`bgWrapper`}>
              <div className={`imgWrapper`}>
                  <img src={isMobile ? tbgMobile: tbg} alt="img" />
                </div>  
              <div className={`content`}>
                <div className={`title`}>{title}</div>
                <div className={`subtitle`}>{subtitle}</div>
                <div className='newDesc'>
                    <div className={`desc desc1`}>{desc1}</div>
                    <div className={`line`}/>
                    <div className={`desc desc2`}>{desc2}</div>
                    <div className={`line`}/>
                    <div className={`desc desc3`}>{desc3}</div>
                   <a target="_blank" rel="noopener noreferrer" href={andro} key="a" className={`word-download`}>
                    <button className="banner-btn"><a    target="_blank" href={appUrl} key="a" rel="noopener noreferrer">
                      {/* <img className="banner-img" src={downLoad} alt='downLoad'/> */}
                      <span className='banner-txt'>Descargar FlexSol</span> </a></button>
                  </a>
                </div>
                
                
             
              </div>
             
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
